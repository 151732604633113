import { kebabCase } from "lodash";
import countryOptions from "~/components/common/countryOptions";

const countryByCode = Object.fromEntries(
  Object.entries(countryOptions).map(function ([code, country]: [
    string,
    any
  ]): [string, string] {
    return [code, (country.label || "").toLowerCase().replace(/-/g, "-")];
  })
);

const countryByName = Object.fromEntries(
  Object.entries(countryOptions).map(function ([code, country]: [
    string,
    any
  ]): [string, string] {
    return [kebabCase(country.label), code];
  })
);

export function countryCodeToName(code: string): string {
  // Turn country code name into name if is needed
  const codeLower = code.toLowerCase();
  return countryByCode[codeLower] || code;
}

export function countryNameToCode(name: string): string {
  // Turn country name into code name
  const nameLower = kebabCase(name);
  return countryByName[nameLower] || name;
}

export function sortCountries(
  countries: Record<string, string>
): Record<string, string> {
  return Object.keys(countries)
    .sort()
    .reduce((obj: any, key: string): any => {
      obj[key] = countries[key];
      return obj;
    }, {});
}

export function validateUsername(username: string): string | null {
  const trimmedUsername = username.trim();
  const regexPattern = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/; // eslint-disable-line
  if (!regexPattern.test(trimmedUsername)) {
    return null;
  }
  return trimmedUsername;
}

export function getSelectedCountries(
  selectedCountries: Array<any>
): Array<string> {
  const data = selectedCountries.map((country: any): string => country.id);
  return [...new Set(data)];
}

export function getSelectedRoles(
  selectedRoles: Array<any>,
  availableRoles: Array<any>
): Array<string> {
  const selectedRolesIds: Array<string> = [];
  selectedRoles.forEach((selectedRole: any): any => {
    if (
      availableRoles.some(
        (availableRole: any): boolean =>
          availableRole.label === selectedRole.label
      )
    ) {
      selectedRolesIds.push(selectedRole.id);
    }
  });

  return [...new Set(selectedRolesIds)];
}

export default {
  name: "components utils",
};

export function snakeCaseToTitle(snakeCase: string): string {
  return snakeCase
    .split("_")
    .map((word: string): string => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
