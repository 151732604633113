import { ActionTree } from "vuex";
import { RootState } from "./state";
import {
  buildPersonsFiltersQuery,
  buildUsersFiltersQuery,
  normalizePipeline,
} from "./utils";

const actions: ActionTree<RootState, RootState> = {
  filterPersons({ commit, state }, { country, page, order, callback }): void {
    commit("setPersonEdited", null);

    const allowed = !!state.countries.countries[country];
    if (!allowed) {
      return;
    }

    if (page) {
      commit("setPersonListPage", page);
    }
    if (order) {
      commit("setPersonListOrder", order);
    }

    const owner = state.currentOwner;
    const url = `persons/${country}?owner=${owner}&${buildPersonsFiltersQuery(
      state
    )}`;
    commit("setPersonListLoading", true);
    const req = this.$axios.$get(url);
    req.then((persons: any): void => {
      Object.assign(persons, { loading: false });
      commit("loadPersons", persons);
      if (callback) {
        callback();
      }
    });
  },

  filterUsers({ commit, state }, { page }): void {
    commit("setUsersLoading", true);
    if (page) {
      commit("setUsersPage", page);
    }
    const owner = state.currentOwner;
    const url = `users?owner=${owner}&${buildUsersFiltersQuery(state)}`;
    const req = this.$axios.$get(url);
    req.then((users: any): void => {
      commit("setUsersLoading", false);
      commit("loadUserList", users);
    });
  },

  setMessage({ commit }, message): void {
    commit("setMsg", message);
  },

  loadPipelines({ commit, getters, state }, { country, owner }): Promise<any> {
    if (getters.isFreeTier) {
      commit("pipelinesLoaded", { country, loaded: true });
      return Promise.resolve();
    } else if (!state.pipelinesLoaded[country]) {
      const url = "pipelines/" + (owner ? `?owner=${owner}` : "");
      return this.$axios.$get(url).then((pipelinesData): void => {
        if (!pipelinesData || !pipelinesData.data) {
          return;
        }
        const pipelines: Array<any> = [];
        pipelinesData.data.forEach((pip: any): void => {
          if (pip.country_code.toLowerCase() === country) {
            pipelines.push(normalizePipeline(pip));
          }
        });
        commit("setPipelines", { country, pipelines });
        commit("pipelinesLoaded", { country, loaded: true });
      });
    } else {
      return Promise.resolve();
    }
  },

  loadAllowedCountries({ commit }): void {
    const storedOwner = localStorage.getItem("currentOwner");
    this.$axios
      .$get(`users/allowed-countries/?owner=${storedOwner}`)
      .then((response: { allowedCountries: Array<string> }): void => {
        commit("setAllowedCountries", [...new Set(response.allowedCountries)]);
      });
  },

  fetchUserData({ commit }): Promise<any> {
    const storedOwner = localStorage.getItem("currentOwner");
    const request = this.$axios.$get(`users/user-data/?owner=${storedOwner}`);
    request.then((data: any): void => {
      if (data.owners) {
        commit("loadOwnersList", data.owners);
        if (
          storedOwner &&
          data.owners.items.find(
            (owner: any): boolean => owner.uid === storedOwner
          )
        ) {
          commit("setOwner", storedOwner);
        } else {
          localStorage.removeItem("currentOwner");
          commit("setOwner", data.owner);
        }
      } else {
        commit("setOwner", data.owner);
      }

      commit("setPermissions", data.permissions);
      commit("setCountries", data.countries);
      commit("setUserLevel", data.userLevel);
      commit("setMetrics", data.metrics);
      commit("setCredits", data.credits);
      commit("setLocale", data.locale);
      commit("userDataLoaded", true);
    });
    return request;
  },

  loadUserData({ state }): Promise<any> {
    if (!state.userDataLoaded) {
      return this.dispatch("fetchUserData");
    } else {
      return Promise.resolve();
    }
  },

  reloadUserData({ commit }): void {
    commit("userDataLoaded", false);
    commit("resetPipelinesLoaded");
    this.dispatch("fetchUserData");
  },

  setUserLocale({ commit }, locale): void {
    commit("setLocale", locale);
    this.$axios.$patch("users/update/locale/", { locale }).then((): void => {
      commit("setLocale", locale);
    });
  },

  loadRoles({ commit }): void {
    const req = this.$axios.$get("users/roles/");
    req.then((roles: any): void => {
      commit("loadRoleList", roles);
    });
  },

  loadUsernames({ commit, state }): void {
    /* Load all the usernames of the current owner. To be used in the
     * `createdBy` filter. */
    const owner = state.currentOwner;
    const url = "users/usernames/" + (owner ? `?owner=${owner}` : "");
    const req = this.$axios.$get(url);
    req.then((usernames: any): void => {
      commit("loadUsernames", usernames);
    });
  },

  addPerson({ commit, state }, person): void {
    if (!state.stopWSLoading && person.country === state.currentCountry) {
      commit("addPerson", person);
    }
  },

  updatePerson({ commit, state }, person): void {
    if (person.country === state.currentCountry) {
      // Normalize reports_details to report_details
      const normalizedPerson = {
        ...person,
        report_details: {},
      };
      if (person.reports_details) {
        person.reports_details.forEach(
          (report: { name: string; [key: string]: any }) => {
            normalizedPerson.report_details[report.name] = report;
          }
        );
      }
      commit("updatePerson", normalizedPerson);
    }
  },

  loadWSErrors({ commit, state }, message): void {
    const email = state.auth.user.email;

    if (message.document_id) {
      commit("removePerson", message);
      commit("setShouldOpenFlyout", false);
    }

    if (email === message.created_by || message.created_by === "api") {
      commit("setWSErrors", message);
    }
  },
};

export default actions;
