const countryOptions = {
  ar: { id: "ar", label: "Argentina", phonePrefix: "+54" },
  br: { id: "br", label: "Brazil", phonePrefix: "+55" },
  cl: { id: "cl", label: "Chile", phonePrefix: "+56" },
  co: { id: "co", label: "Colombia", phonePrefix: "+57" },
  cr: { id: "cr", label: "Costa Rica", phonePrefix: "+506" },
  ec: { id: "ec", label: "Ecuador", phonePrefix: "+593" },
  mx: { id: "mx", label: "Mexico", phonePrefix: "+52" },
  pa: { id: "pa", label: "Panama", phonePrefix: "+507" },
  pe: { id: "pe", label: "Peru", phonePrefix: "+51" },
  xx: { id: "xx", label: "Emptorland", phonePrefix: "+00" },
};

export default countryOptions;
