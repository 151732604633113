import { Pipeline, User } from "@/types/app";
import { cloneDeep, uniqueId } from "lodash";
import { MutationTree } from "vuex";
import { RootState } from "./state";
import { defaultPersons, normalizePipeline } from "./utils";

const mutations: MutationTree<RootState> = {
  setUserLevel(state, userLevel): void {
    state.userLevel = userLevel;
  },
  setCountrySelected(state, data): void {
    state.countrySelected = data;
  },
  setCountries(state, data): void {
    if (state.countries.default !== data.default) {
      state.countries.default = data.default;
    }
    if (state.countries.countries !== data.countries) {
      state.countries.countries = data.countries;
    }
  },

  setAllowedCountries(state, allowedCountries): void {
    state.allowedCountries = allowedCountries;
  },

  userDataLoaded(state, isLoaded): void {
    state.userDataLoaded = isLoaded;
  },

  setMetrics(state, metrics): void {
    const { uptime, ...profileMetrics } = metrics;

    state.metrics = profileMetrics;
    state.uptime = uptime;
  },

  setCredits(state, credits: number | undefined): void {
    state.credits = credits;
  },

  setOwner(state, owner: string): void {
    state.currentOwner = owner;
  },

  setPermissions(state, data: any): void {
    state.permissions = {
      canDeletePersons: !!data.can_delete,
      canManageUsers: !!data.can_manage_users,
      canReloadPersons: !!data.can_reload,
      canEditPersons: !!data.can_edit,
      canSeeAllPersons: !!data.can_see_all,
      canSwitchOwner: !!data.can_switch_owner,
      canUseAdvanced: !!data.can_advanced,
      canCreatePipeline: !!data.can_create_pipeline,
      canUpdatePipeline: !!data.can_update_pipeline,
      canDeletePipeline: !!data.can_delete_pipeline,
      canCreatePreCandidate: !!data.can_create_precandidate,
    };
  },

  cleanPersonSelected(state): void {
    state.personSelected = {};
    state.flyoutOpenedDefault = false;
  },

  setShouldOpenFlyout(state, open: boolean): void {
    state.isFlyoutOpen = open;
    if (open) {
      state.personEdited = null;
      state.showAddPerson = false;
    } else {
      state.flyoutOpenedDefault = false;
      state.personSelected = {};
    }
  },

  setShouldOpenUserFlyout(state, open: boolean): void {
    state.shouldOpenUserFlyout = open;
    if (!open) {
      state.usersEdited = new Set();
    }
  },

  setShouldOpenOwnerFlyout(state, open: boolean): void {
    state.shouldOpenOwnerFlyout = open;
    if (open) {
      state.showAddPerson = false;
    }
  },

  setOwnerEdited(state, owner: any = null): void {
    state.ownerEdited = owner;
  },

  setFlyoutOpenedDefault(state, data): void {
    state.flyoutOpenedDefault = data;
  },

  setShowAddPerson(state, show: boolean): void {
    state.showAddPerson = !!show;
  },

  setPersonSelected(state, data: any): void {
    state.personSelected = data;
  },

  setPersonEdited(state, data: any): void {
    state.personEdited = data;
  },

  setPersonFormPipelineId(state, pipelineId: string): void {
    state.personFormPipelineId = pipelineId;
  },

  setPersonFormReports(state, reports: Array<string> = []): void {
    state.personFormReports = [...reports];
  },

  setPersonFormData(state, data: any = {}): void {
    state.personFormData = cloneDeep(data);
  },

  setEditingPipeline(state, data: boolean): void {
    state.editingPipeline = data;
  },

  setUsersEdited(state, users): void {
    state.usersEdited = users;
  },

  selectUser(state, user): void {
    state.usersSelected.add(user);
    state.usersSelected = new Set(state.usersSelected);
  },

  deSelectUser(state, user): void {
    state.usersSelected.delete(user);
    state.usersSelected = new Set(state.usersSelected);
  },

  clearUsersSelection(state): void {
    state.usersSelected = new Set();
  },

  clearPersons(state): void {
    state.persons = defaultPersons();
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  clearPersonsSelection(state): void {
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  loadPersons(state, data): void {
    state.personEdited = null;
    const now = new Date();
    data.items.forEach(function (item: any): void {
      item.loaded_at = now;
    });
    state.persons = { ...state.persons, ...data };
  },

  addPerson(state, data): void {
    if (state.persons.page === 1) {
      state.persons.items.unshift(data);
      state.personsAllSelected = false;
      if (state.flyoutOpenedDefault) {
        state.personSelected = state.persons.items[0];
      }
    }
  },

  setCurrentCountry(state, country): void {
    state.currentCountry = country;
  },

  removePerson(state, data): void {
    state.persons.items = state.persons.items.filter(function (item: any): any {
      return item.id !== data.id;
    });
  },

  updatePerson(state, data): void {
    const items = [...state.persons.items];
    const index = items.findIndex(function (p: { id: any }): boolean {
      return p.id === data.id;
    });
    if (index === -1) {
      return;
    }
    const person = items[index];
    Object.assign(person, data);
    person.loaded_at = new Date();
    items.splice(index, 1, person);
    state.persons.items = items;

    if (person.id === state.personSelected.id) {
      // Update the personSelected with the new data, including reports
      state.personSelected = {
        ...state.personSelected,
        ...person,
        report_details:
          data.report_details || state.personSelected.report_details,
      };
    }
  },

  addPersonListFilter(state, filter): void {
    filter.uid = uniqueId("f");
    state.persons.filters.push(filter);
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  removePersonListFilter(state, uid): void {
    const index = state.persons.filters.findIndex(function (item: {
      uid: any;
    }): boolean {
      return item.uid === uid;
    });
    if (index !== -1) {
      state.persons.filters.splice(index, 1);
    }
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  filterPersonsListBySearchTerms(state, searchTerms): void {
    state.persons.searchTerms = searchTerms;
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  filterPersonListByDocumentId(state, documentId): void {
    state.persons.document_id = documentId;
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  selectPerson(state, id): void {
    state.personsAllSelected = false;
    state.personsSelected.add(id);
    // A new set is created in order to force reactivity in vue
    state.personsSelected = new Set(state.personsSelected);
  },

  deSelectPerson(state, id): void {
    state.personsSelected.delete(id);
    // A new set is created in order to force reactivity in vue
    state.personsSelected = new Set(state.personsSelected);
    state.personsAllSelected = false;
  },

  selectAllPersons(state): void {
    state.personsAllSelected = true;
  },

  setPersonListPage(state, page): void {
    state.persons.page = page;
  },

  setPersonListOrder(state, order): void {
    state.persons.order = order;
    state.personsSelected = new Set();
    state.personsAllSelected = false;
  },

  setPersonListLoading(state, loading): void {
    Object.assign(state.persons, { loading });
  },

  setMsg(state, message): void {
    state.generalMsg = message;
  },

  closeSidebar(state): void {
    state.sidebarOpen = false;
  },

  toggleSidebar(state): void {
    state.sidebarOpen = !state.sidebarOpen;
  },

  togglePersonFilter(state): void {
    state.showPersonFilter = !state.showPersonFilter;
  },

  toggleUserFilterForm(state): void {
    state.showUserFilterForm = !state.showUserFilterForm;
  },

  setPipelines(
    state,
    pipelinesData: { country: string; pipelines: Array<any> }
  ): void {
    const { country, pipelines } = pipelinesData;
    state.pipelines[country] = pipelines;
  },

  resetPipelinesLoaded(state): void {
    for (const country of Object.keys(state.pipelinesLoaded)) {
      state.pipelinesLoaded[country] = false;
    }

    for (const country of Object.keys(state.pipelines)) {
      state.pipelines[country] = [];
    }
  },

  pipelinesLoaded(
    state,
    loadedData: { country: string; loaded: boolean }
  ): void {
    const { country, loaded } = loadedData;
    state.pipelinesLoaded[country] = loaded;
  },

  addPipeline(state, pipelineData: { country: string; pipeline: any }): void {
    const { country, pipeline } = pipelineData;
    const newPipeline = normalizePipeline(pipeline);
    state.pipelines[country].push(newPipeline);
  },

  updatePipeline(
    state,
    pipelineData: { country: string; pipeline: any }
  ): void {
    const { country, pipeline } = pipelineData;
    const newPipeline = normalizePipeline(pipeline);

    const index = state.pipelines[country].findIndex((p: Pipeline): boolean => {
      return p.id === newPipeline.id;
    });

    if (index > -1) {
      state.pipelines[country].splice(index, 1, newPipeline);
    }
  },

  deletePipeline(
    state,
    pipelineData: { country: string; pipelineId: string }
  ): void {
    const { country, pipelineId } = pipelineData;
    const index = state.pipelines[country].findIndex((p: Pipeline): boolean => {
      return p.id === pipelineId;
    });

    if (index > -1) {
      state.pipelines[country].splice(index, 1);
    }
  },

  loadOwnersList(state, owners): void {
    state.owners = { ...state.owners, ...owners };
  },
  loadUserList(state, users): void {
    state.users = { ...state.users, ...users };
  },
  loadRoleList(state, { roles }): void {
    state.availableRoles = roles;
  },
  loadUsernames(state, usernames): void {
    state.usernames = usernames;
  },
  removeUser(state, user: User): void {
    state.users.items = state.users.items.filter(
      (item: User): boolean => item.id !== user.id
    );
  },
  addUser(state, user: User): void {
    mutations.removeUser(state, user);
    state.users.items.unshift(user);
  },
  addUsersFilter(state, filter): void {
    filter.uid = uniqueId("f");
    state.users.filters.push(filter);
    state.usersSelected = new Set();
    state.usersEdited = new Set();
  },
  removeUsersFilter(state, uid): void {
    const index = state.users.filters.findIndex(function (item: {
      uid: any;
    }): boolean {
      return item.uid === uid;
    });
    if (index !== -1) {
      state.users.filters.splice(index, 1);
    }
    state.usersSelected = new Set();
    state.usersEdited = new Set();
  },
  filterUsersByUserNames(state, userNames): void {
    state.users.searchTerms = userNames;
    state.usersSelected = new Set();
    state.usersEdited = new Set();
  },
  setUsersLoading(state, loading): void {
    Object.assign(state.users, { loading });
  },
  setUsersPage(state, page): void {
    state.users.page = page;
  },
  addOrUpdateOwner(state, data): void {
    let owner: any, index: number;
    for (index = 0; state.owners.items[index]; index++) {
      owner = state.owners.items[index];
      if (owner.uid === data.uid) {
        Object.assign(owner, data);
        return;
      }
    }
    state.owners.items.unshift(data);
  },
  setLocale(state, locale): void {
    state.locale = locale;
  },
  setWSErrors(state, error): void {
    state.wsErrors.push(error);
  },
  clearWSErrors(state): void {
    state.wsErrors = [];
  },
  setStopWSLoading(state, flag): void {
    state.stopWSLoading = flag;
  },
};

export default mutations;
